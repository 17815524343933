import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Avatar } from 'antd';
import { dateOptions } from '../utils/helpers';
import { missingImage } from '.';

const CampaignCard = props => {
	const navigate = useNavigate();

	return (
		<div className='campaign-card'>
			<div
				onClick={() => navigate(`/campaign/${props?.campaign?.campaignId}`)}
				className='card-wrapper'>
				<div className='img-wrapper'>
					<img alt='campaign-card' src={props?.campaign?.collectionBannerUrl ?? missingImage} />
				</div>
				<Avatar
					className='campaign-avatar'
					src={props?.campaign?.collectionLogoUrl ?? missingImage}
					size={props?.avatarSize ?? 60}
				/>
				<div className='info-wrapper'>
					<Space size={props?.infoSize ?? 3} direction='vertical' align='center'>
						<span className='collection-name bold'>
							{props?.campaign?.collectionName}
						</span>
						<div className='client-name'>
							<span>
								by{' '}
								{
									props?.clients?.find(
										client => client.clientId === props?.campaign?.clientId
									)?.clientName
								}
							</span>
						</div>
					</Space>
				</div>
				<div className='date-wrapper'>
					<Space align='center'>
						{Number(props?.campaign?.endDate) >= Date.now() ? (
							<Space>
								<span className='info'>Ending</span>
								<span className='date'>
									{new Date(Number(props?.campaign?.endDate)).toLocaleString(
										'en-us',
										dateOptions
									)}
								</span>
							</Space>
						) : (
							<span className='info'>Ended</span>
						)}
					</Space>
				</div>
			</div>
		</div>
	);
};

export default CampaignCard;
