import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space } from 'antd';
import { Layout, RegisterForm, GoogleIcon } from '../components';
import { signInWithGoogle } from '../utils/firebase';
import { AppContext } from '../context/GlobalState';

const Register = () => {
	const navigate = useNavigate();
	const [registerEmail, setRegisterEmail] = useState(false);
	const { isLoggedIn } = useContext(AppContext);

	useEffect(() => {
		isLoggedIn && navigate('/home');
	}, [isLoggedIn, registerEmail]); // eslint-disable-line

	const callback = show => {
		setRegisterEmail(show);
	};

	return (
		<Layout login>
			{registerEmail ? (
				<RegisterForm callback={callback} />
			) : (
				<div className='login-wrapper'>
					<div className='register-box'>
						<span className='header'>
							Join TINT
							<span>Marketer Portal</span>
						</span>
						<Space align='center' size={40} direction='vertical'>
							<Space size='middle' direction='vertical'>
								<button onClick={() => signInWithGoogle()} className='secondary-btn'>
									<GoogleIcon /> Sign up with Google
								</button>
								<button onClick={() => setRegisterEmail(true)} className='secondary-btn'>
									Sign up with E-mail
								</button>
							</Space>
							<div className='sign-in-tos'>
								<span>
									By creating an account, you agree to <br />
									<strong>our Terms of Service.</strong>
								</span>
							</div>
						</Space>
					</div>
					<div onClick={() => navigate('/login')} className='register-footer'>
						<span>
							Already have an account? <strong>Sign in</strong>
						</span>
					</div>
				</div>
			)}
		</Layout>
	);
};
export default Register;
