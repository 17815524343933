import React from 'react';
import { Modal } from 'antd';

const DeleteModal = ({ visible, onFinish, onCancel, itemId }) => (
	<Modal
		visible={visible}
		okText='Delete'
		cancelText='Cancel'
    centered
    title={`Are you sure you want to delete item "${itemId}"?`}
		cancelButtonProps={{ className: 'primary-btn' }}
		okButtonProps={{ className: 'secondary-btn' }}
		onOk={onFinish}
		onCancel={onCancel}
		className='delete-modal'>
		<div className='delete-content-wrapper'>
        This item will be deleted immediately.
		</div>
	</Modal>
);

export default DeleteModal;