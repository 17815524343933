import React, { useEffect, useState } from 'react';
import { Modal, Input, Checkbox, notification, Space } from 'antd';
import { isEmpty } from 'lodash';

const validateEmail = email => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

const InviteModal = ({ visible, callback, cancelCallback, collections }) => {
	const [email, setEmail] = useState('');
	const [open, setOpen] = useState(visible);
	const [checkedList, setCheckedList] = useState([]);
	const [indeterminate, setIndeterminate] = useState(false);
	const [checkAll, setCheckAll] = useState(false);

	const options = collections?.map(({ collectionName, campaignId }) => ({
		label: collectionName,
		value: campaignId
	}));

	useEffect(() => {
		setOpen(visible);
		setEmail('');
	}, [visible]);

	const onSubmit = () => {
		if (!isEmpty(email)) {
			if (validateEmail(email)) {
				callback(email, checkedList);
				setOpen(false);
				setEmail('');
				setCheckedList([]);
			} else {
				notification['error']({
					duration: 10,
					message: `User's email is invalid`
				});
			}
		} else {
			notification['error']({
				duration: 10,
				message: `User's email is required`
			});
		}
	};

	const onCancel = () => {
		cancelCallback();
	};

	const onInputValueChange = e => {
		setEmail(e.target.value);
	};

	const onSelect = checkedValues => {
		setCheckedList(checkedValues);
		setIndeterminate(!!checkedValues.length && checkedValues.length < options.length);
		setCheckAll(checkedValues.length === options.length);
	};

	const onCheckAll = e => {
		setCheckedList(e.target.checked ? options?.map(({ value }) => value) : []);
		setIndeterminate(false);
		setCheckAll(e.target.checked);
	};

	return (
		<Modal
			visible={open}
			closable
			okText='Send invite'
			okButtonProps={{ className: 'primary-btn' }}
			onOk={onSubmit}
			cancelButtonProps={{ style: { display: 'none' } }}
			centered
			title='Send invite'
			className='invite-modal'
			onCancel={onCancel}>
			<div className='modal-popup'>
				<Space className='email-wrapper' size={15} direction='vertical'>
					<span className='label'> Insert e-mail address</span>
					<Input
						placeholder='E-mail address'
						type='text'
						className='input'
						onChange={onInputValueChange}
						value={email}
					/>
				</Space>
				<div className='divider'></div>
				<div className='header'> Select campaigns</div>
				<Checkbox indeterminate={indeterminate} onChange={onCheckAll} checked={checkAll}>
					Select all
				</Checkbox>
				<div className='modal-popup-collections-wrapper'>
					<Checkbox.Group options={options} value={checkedList} onChange={onSelect} />
				</div>
			</div>
		</Modal>
	);
};

export default InviteModal;
