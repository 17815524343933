import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, Avatar, Dropdown, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { CreateClientModal, ConfirmModal } from '.';
import callApi from '../utils/callApi';
import { AppContext } from '../context/GlobalState';
import { tintLogo } from '.';

const Navbar = () => {
	const navigate = useNavigate();
	const [createLoading, setCreateLoading] = useState(false);
	const [createVisible, setCreateVisible] = useState(false);
	const [createErr, setCreateErr] = useState(false);
	const [confirmVisible, setConfirmVisible] = useState(false);
	const [createMessage, setCreateModalMessage] = useState();
	const { userLogout, user, clients, fetchClients } = useContext(AppContext);

	const onLogout = async () => {
		userLogout();
		navigate('/register');
	};

	const handleMenuClick = e => {
		switch (e.key) {
			case 'log-out':
				onLogout();
				break;
			default:
				break;
		}
	};

	const onCreate = async values => {
		setCreateLoading(true);
		const clientInfo = {
			clientName: values.name,
			clientDescription: values.description,
			clientContactDetails: values.contactDetails
		};
		if (user?.agencyId) {
			clientInfo.agencies = [user?.agencyId];
		}
		try {
			const response = await callApi('put', 'client', clientInfo);
			if (!response?.error || !response?.status === 'error') {
				await fetchClients();
				setCreateLoading(false);
				setCreateModalMessage('Client created succesfully');
				setCreateVisible(false);
				setConfirmVisible(true);
			} else {
				setCreateLoading(false);
				setCreateErr(true);
				setCreateModalMessage(response?.message);
				setCreateVisible(false);
				setConfirmVisible(true);
				console.log('Error while creating client', response?.error);
			}
		} catch (err) {
			setCreateLoading(false);
			console.error('Error while creating client', err);
			setCreateVisible(false);
			setConfirmVisible(true);
		}
	};

	const userMenu = (
		<Menu className='avatar-menu' onClick={handleMenuClick}>
			<Menu.Item key='log-out' className='avatar-menu-item'>
				Log out
			</Menu.Item>
		</Menu>
	);

	return (
		<div className='header-wrapper'>
			<Link to={'/home'} className='title'>
				<img alt='Tint' src={tintLogo} />
				<span>Marketer Portal</span>
			</Link>
			<Space size='middle' align='center'>
				{user?.role && (
					<Space size='large'>
						<Link to={'/create-campaign'}>Create Campaign</Link>
						<button onClick={() => setCreateVisible(true)}>Create Client</button>
					</Space>
				)}
				<Dropdown className='avatar-dropdown' overlay={userMenu} placement='bottomRight'>
					<Avatar size={40} src={user?.photoURL} icon={<UserOutlined />} />
				</Dropdown>
			</Space>
			<CreateClientModal
				visible={createVisible}
				loading={createLoading}
				onCancel={() => setCreateVisible(false)}
				onCreate={onCreate}
				err={createErr}
				clients={clients}
			/>
			<ConfirmModal
				err={createErr}
				message={createMessage}
				visible={confirmVisible}
				onFinish={() => setConfirmVisible(false)}
			/>
		</div>
	);
};

export default Navbar;
