import React, { useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spin, notification } from 'antd';
import { isEmpty } from 'lodash';
import callApi from '../utils/callApi';
import { Register } from '.';
import { AppContext } from "../context/GlobalState";

const Invite = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { isLoggedIn, user } = useContext(AppContext);

  useEffect(() => {
    !isEmpty(user) && isLoggedIn && processInvite();
  }, [isLoggedIn, JSON.stringify(user)]); // eslint-disable-line react-hooks/exhaustive-deps

  const processInvite = async () => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    console.log('Invite', params);
    try {
      const response = await callApi('post', 'invite', params);
      if (response?.error || response?.status === 'error') {
        notification['error']({
          duration: 10,
          message: 'An error occurred',
          description: response?.message || response?.error
        });
      } else {
        navigate('/');
      }
    } catch (err) {
      console.error('Error', err);
    }
  }

  if (isEmpty(user) || !isLoggedIn) {
    return <Register presetEmail={params?.email} />
  } 
  return <Spin size="large" className="full-page-loader" />
}

export default Invite;
