import React, { useEffect, useState, useCallback } from 'react';
import { Checkbox, Input, Select, Popover, notification } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { InviteModal, CampaignsModal, Layout, Loading } from '../components';
import callApi from '../utils/callApi';
import sortByValue from '../utils/sorting';

const { Option } = Select;

const AssignUsers = () => {
	const [loading, setLoading] = useState(false);
	const [inviteModal, setInviteModal] = useState(false);
	const [campaignsModal, setCampaignsModal] = useState(false);
	const [collections, setCollections] = useState([]);
	const [users, setUsers] = useState([]);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [invites, setInvites] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	const [searchQuery, setSearchQuery] = useState('');
	const [sortBy, setSortBy] = useState('invite');
	const [checkedList, setCheckedList] = useState([]);
	const [checkedListOptions, setCheckedListOptions] = useState([]);
	const [, updateState] = useState();

	useEffect(() => {
		fetchData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		let filteredUsers = users;
		if (checkedList?.length) {
			filteredUsers = users?.filter(user => {
				let hasCampaign = false;
				user?.campaignIds?.forEach(campaignId => {
					if (checkedList.includes(campaignId)) {
						hasCampaign = true;
					}
				});
				return hasCampaign;
			});
		}
		const filtered = sortByValue(filteredUsers, sortBy, searchQuery);

		setFilteredUsers(filtered);
		forceUpdate();
	}, [searchQuery, users?.length, sortBy, checkedList]); // eslint-disable-line

	const forceUpdate = useCallback(() => updateState({}), []);

	const fetchData = async () => {
		await fetchCollections();
		await fetchUsers();
		await fetchInvites();
		setLoading(false);
	};

	const fetchCollections = async () => {
		const collectionsResponse = await callApi('get', 'collections');
		if (!collectionsResponse?.error && collectionsResponse?.status !== 'error') {
			collectionsResponse?.collections &&
				setCollections(collectionsResponse?.collections);

			const options = collectionsResponse?.collections?.map(
				({ collectionName, campaignId }) => ({
					label: collectionName,
					value: campaignId
				})
			);
			setCheckedListOptions(options);
		} else {
			notification['error']({
				duration: 10,
				message: 'An error occurred',
				description: collectionsResponse?.message
			});
		}
	};

	const fetchUsers = async () => {
		const usersResponse = await callApi('get', 'users');
		if (!usersResponse?.error && usersResponse?.status !== 'error') {
			usersResponse?.users &&
				setUsers(usersResponse?.users?.filter(user => user?.role !== 'admin')) &&
				setFilteredUsers(usersResponse?.users?.filter(user => user?.role !== 'admin'));

			!isEmpty(invites) && assignInvitesToUsers();
		} else {
			notification['error']({
				duration: 10,
				message: 'An error occurred',
				description: usersResponse?.message
			});
		}
	};

	const fetchInvites = async () => {
		const invitesResponse = await callApi('get', 'invites');
		if (!invitesResponse?.error && invitesResponse?.status !== 'error') {
			invitesResponse?.invites && setInvites(invitesResponse?.invites);
			!isEmpty(users) && assignInvitesToUsers();
		} else {
			notification['error']({
				duration: 10,
				message: 'An error occurred',
				description: invitesResponse?.message
			});
		}
	};

	const assignInvitesToUsers = () => {
		users.forEach(user => {
			const invite = invites?.find(invite => invite?.email === user?.email);
			if (!isEmpty(invite)) {
				user.inviteTimestamp = invite?.timestamp;
			}
		});
		setUsers(users);
		setFilteredUsers(users);
		setSortBy('invite');
	};

	const inviteUser = async (email, campaignIds) => {
		setInviteModal(false);
		setLoading(true);
		await callApi('put', 'invite', { email, campaignIds });
		setLoading(false);
	};

	const updateCampaigns = async campaignIds => {
		setCampaignsModal(false);
		setLoading(true);
		await callApi('post', 'user', { uid: selectedUser, campaignIds });
		await fetchUsers();
		setLoading(false);
		setSelectedUser(null);
	};

	const onEditRights = uid => {
		setSelectedUser(uid);
		setCampaignsModal(true);
	};

	const CampaignsSelector = () => (
		<Checkbox.Group
			options={checkedListOptions}
			value={checkedList}
			onChange={setCheckedList}
		/>
	);

	return (
		<Layout>
			{loading ? (
				<Loading />
			) : (
				<div className='assign-page'>
					<div className='header'>Access rights</div>
					<div className='divider'></div>
					<div className='content-wrapper'>
						<div className='assign-header-wrapper'>
							<span className='header'>Users Overview</span>
							<button onClick={() => setInviteModal(true)} className='primary-btn'>
								Send invite
							</button>
						</div>
						<div className='sub-header-wrapper'>
							<Popover
							getPopupContainer={trigger => trigger.parentElement}
								content={<CampaignsSelector />}
								trigger='click'
								placement='bottom'>
								<button className='secondary-btn'>Campaigns</button>
							</Popover>
							<Input
								prefix={<SearchOutlined />}
								placeholder='Search'
								onChange={e => setSearchQuery(e.target.value)}
							/>
							<Select defaultValue='invite' onChange={setSortBy}>
								<Option value='invite'>Sort by Latest invite</Option>
								<Option value='name'>Sort by Name</Option>
								<Option value='email'>Sort by Email</Option>
							</Select>
						</div>
						<div className='users-wrapper'>
							{filteredUsers?.map(user => (
								<div key={user?.uid} className='user-box'>
									<span className='info-1'>{user?.displayName}</span>
									<span className='info-2'>{user.email}</span>
									<button
										className='tertiary-btn'
										onClick={() => onEditRights(user?.uid)}>
										Edit rights
									</button>
									{user?.campaignIds?.map(campaignId => (
										<div key={campaignId} className=''>
											{
												collections?.find(
													collection => collection?.campaignId === campaignId
												)?.collectionName
											}
										</div>
									))}
								</div>
							))}
						</div>
					</div>
				</div>
			)}
			<InviteModal
				visible={inviteModal}
				callback={inviteUser}
				collections={collections}
				cancelCallback={() => setInviteModal(false)}
			/>
			<CampaignsModal
				visible={campaignsModal}
				callback={updateCampaigns}
				collections={collections}
				cancelCallback={() => setCampaignsModal(false)}
			/>
		</Layout>
	);
};

export default AssignUsers;
