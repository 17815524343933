import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, notification } from 'antd';
import { isEmpty } from 'lodash';
import { customAlphabet } from 'nanoid';
import { Layout, Loading } from '../components';
import { AppContext } from '../context/GlobalState';
import callApi from '../utils/callApi';

const Admin = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [columns, setColumns] = useState([]);
	const { isLoggedIn, user } = useContext(AppContext);

	useEffect(() => {
		if (isLoggedIn && !isEmpty(user) && user?.role === 'admin') {
			setLoading(false);
		}
		if (isLoggedIn && !isEmpty(user) && user?.role !== 'admin') {
			navigate(`/home`);
		}
	}, [JSON.stringify(user)]); // eslint-disable-line

	const createUser = async () => {
		setLoading(true);
		try {
			const payload = {
				firstName: 'Alexey',
				lastName: 'Test',
				displayName: 'Alexey Test',
				clientId: 'LQMSDC',
				agencyId: 'IDRJYZ'
			};
			const response = await callApi('put', 'user', payload);
			if (response?.error || response?.status === 'error') {
				showError(response?.message || response?.error);
			} else {
				showSuccess();
			}
			setLoading(false);
		} catch (err) {
			console.error('Error while adding funds', err);
		}
	};

	const createAdminUser = async () => {
		setLoading(true);
		try {
			const payload = {
				firstName: 'Alexey',
				lastName: 'Admin',
				displayName: 'Alexey Admin'
			};
			const response = await callApi('put', 'user', payload);
			if (response?.error || response?.status === 'error') {
				showError(response?.message || response?.error);
			} else {
				showSuccess();
			}
			setLoading(false);
		} catch (err) {
			console.error('Error while adding funds', err);
		}
	};

	const createAgency = async () => {
		setLoading(true);
		try {
			const payload = {
				agencyDescription: 'We invented marketing',
				agencyName: 'Marketing Agency',
				agencyContactDetails: 'Berlin',
				contactPersonName: 'Maqa',
				contactPersonEmail: 'q@q.com'
			};
			const response = await callApi('put', 'agency', payload);
			if (response?.error || response?.status === 'error') {
				showError(response?.message || response?.error);
			} else {
				showSuccess();
			}
			setLoading(false);
		} catch (err) {
			console.error('Error while adding funds', err);
		}
	};

	const createClient = async () => {
		setLoading(true);
		try {
			const payload = {
				clientDescription: 'VIP Client',
				clientName: 'VIP',
				clientContactDetails: 'Berlin',
				clientPersonName: 'Alexey Test',
				clientPersonEmail: 'q@q.com'
			};
			const response = await callApi('put', 'client', payload);
			if (response?.error || response?.status === 'error') {
				showError(response?.message || response?.error);
			} else {
				showSuccess();
			}
			setLoading(false);
		} catch (err) {
			console.error('Error while adding funds', err);
		}
	};

	const assignClientToAgency = async () => {
		setLoading(true);
		try {
			const payload = {
				clientId: 'ES5P7K',
				agencyId: 'IDRJYZ'
			};
			const response = await callApi('post', 'admin', payload);
			if (response?.error || response?.status === 'error') {
				showError(response?.message || response?.error);
			} else {
				showSuccess();
			}
			setLoading(false);
		} catch (err) {
			console.error('Error while adding funds', err);
		}
	};

	const assignToAgency = async () => {
		setLoading(true);
		try {
			const payload = {
				agencyId: 'IDRJYZ'
			};
			const response = await callApi('post', 'user', payload);
			if (response?.error || response?.status === 'error') {
				showError(response?.message || response?.error);
			} else {
				showSuccess();
			}
			setLoading(false);
		} catch (err) {
			console.error('Error while adding funds', err);
		}
	};

	const listUsers = async () => {
		setLoading(true);
		try {
			const response = await callApi('get', 'users');
			if (response?.error || response?.status === 'error') {
				showError(response?.message || response?.error);
			} else {
				const columns = Object.keys(response?.users?.[0]).map(key => ({
					title: key,
					dataIndex: key,
					key
				}));
				setColumns(columns);
				setData(response?.users);
			}
			setLoading(false);
		} catch (err) {
			console.error('Error while adding funds', err);
		}
	};

	const listAgencies = async () => {
		setLoading(true);
		try {
			const response = await callApi('get', 'agencies');
			if (response?.error || response?.status === 'error') {
				showError(response?.message || response?.error);
			} else {
				const columns = Object.keys(response?.agencies?.[0]).map(key => ({
					title: key.replace('agency', ''),
					dataIndex: key,
					key
				}));
				setColumns(columns);
				setData(response?.agencies);
			}
			setLoading(false);
		} catch (err) {
			console.error('Error while adding funds', err);
		}
	};

	const listClients = async () => {
		setLoading(true);
		try {
			const response = await callApi('get', 'clients');
			if (response?.error || response?.status === 'error') {
				showError(response?.message || response?.error);
			} else {
				const columns = Object.keys(response?.clients?.[0]).map(key => ({
					title: key.replace('client', ''),
					dataIndex: key,
					key
				}));
				setColumns(columns);
				setData(response?.clients);
			}
			setLoading(false);
		} catch (err) {
			console.error('Error while adding funds', err);
		}
	};

	const listCampaigns = async () => {
		setLoading(true);
		try {
			const response = await callApi('get', 'campaigns');
			if (response?.error || response?.status === 'error') {
				showError(response?.message || response?.error);
			} else {
				const columns = Object.keys(response?.campaigns?.[0]).map(key => ({
					title: key.replace('campaign', ''),
					dataIndex: key,
					key
				}));
				setColumns(columns);
				setData(response?.campaigns);
			}
			setLoading(false);
		} catch (err) {
			console.error('Error while adding funds', err);
		}
	};

	const listCollections = async () => {
		setLoading(true);
		try {
			const response = await callApi('get', 'collections');
			if (response?.error || response?.status === 'error') {
				showError(response?.message || response?.error);
			} else {
				const columns = Object.keys(response?.collections?.[0]).map(key => ({
					title: key.replace('collection', ''),
					dataIndex: key,
					key,
					width: 10
				}));
				setColumns(columns);
				setData(response?.collections);
			}
			setLoading(false);
		} catch (err) {
			console.error('Error while adding funds', err);
		}
	};

	const createCollection = async () => {
		setLoading(true);
		try {
			const payload = {
				clientId: 'LQMSDC',
				agencyId: 'IDRJYZ',
				campaignId: 'MWK1DJ',
				releaseDate: '1644374710000',
				collectionName: 'First collection',
				collectionDescription: 'My first collection',
				websiteUrl: 'http://localhost:3000',
				collectionLogoUrl: 'http://localhost:3000',
				collectionBannerUrl: 'http://localhost:3000',
				collectionType: 'Collectible'
			};
			const response = await callApi('put', 'collection', payload);
			if (response?.error || response?.status === 'error') {
				showError(response?.message || response?.error);
			} else {
				showSuccess();
			}
			setLoading(false);
		} catch (err) {
			console.error('Error while adding funds', err);
		}
	};

	const createCampaign = async () => {
		setLoading(true);
		try {
			const nanoid = customAlphabet('ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789', 10);
			const payload = {
				startDate: '1644374710000',
				endDate: '1646474710000',
				clientId: 'LQMSDC',
				agencyId: 'IDRJYZ',
				campaignUrl: 'http://localhost:3000',
				campaignId: nanoid(6)
			};
			const response = await callApi('put', 'campaign', payload);
			if (response?.error || response?.status === 'error') {
				showError(response?.message || response?.error);
			} else {
				showSuccess();
			}
			setLoading(false);
		} catch (err) {
			console.error('Error while adding funds', err);
		}
	};

	const showError = message => {
		notification['error']({
			duration: 10,
			message: 'An error occurred',
			description: message
		});
	};

	const showSuccess = () => {
		notification['success']({
			duration: 10,
			message: 'Success',
			description: 'Operation completed successfully'
		});
	};

	return (
		<Layout>
			<div className='admin-page'>
				<div className='header'>Admin panel</div>
				<div className='divider'></div>
				<div className='content-wrapper'>
					<div className='sub-header-wrapper'>
						<div className='row'>
							<button className='tertiary-btn' onClick={createUser}>
								Create user
							</button>
							<button className='tertiary-btn' onClick={createAdminUser}>
								Create admin user
							</button>
							<button className='tertiary-btn' onClick={createAgency}>
								Create agency
							</button>
							<button className='tertiary-btn' onClick={createClient}>
								Create client
							</button>
						</div>
						<div className='row'>
							<button className='tertiary-btn' onClick={assignClientToAgency}>
								Assign client to agency
							</button>
							<button className='tertiary-btn' onClick={assignToAgency}>
								Assign user to agency
							</button>
							<button className='tertiary-btn' onClick={createCampaign}>
								Create campaign
							</button>
							<button className='tertiary-btn' onClick={createCollection}>
								Create collection
							</button>
						</div>
						<div className='row'>
							<button className='tertiary-btn' onClick={listUsers}>
								List users
							</button>
							<button className='tertiary-btn' onClick={listAgencies}>
								List Agencies
							</button>
							<button className='tertiary-btn' onClick={listClients}>
								List Clients
							</button>
							<button className='tertiary-btn' onClick={listCampaigns}>
								List Campaigns
							</button>
							<button className='tertiary-btn' onClick={listCollections}>
								List Collections
							</button>
						</div>
					</div>
					{loading ? (
						<Loading />
					) : (
						<div className='table-wrapper'>
							{!isEmpty(data) && (
								<Table
									columns={columns}
									dataSource={data}
									scroll={{ x: 200 }}
									rowKey={record =>
										record?.uid ||
										record?.agencyId ||
										record?.clientId ||
										record?.campaignId ||
										record?.collectionId
									}
								/>
							)}
						</div>
					)}
				</div>
			</div>
		</Layout>
	);
};

export default Admin;
