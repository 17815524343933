import React, { Fragment, useState } from 'react';
import { notification, Space, Avatar, Image } from 'antd';
import { isEmpty } from 'lodash';
import { DeleteOutlined } from '@ant-design/icons';
import callApi from '../utils/callApi';
import { dateOptions } from '../utils/helpers';
import { missingImage, DeleteModal, Loading } from '.';

const NftCard = props => {
	const [loading, setLoading] = useState(false);
	const [deleteVisible, setDeleteVisible] = useState(false);

	const handleDeleteClick = async () => {
		setLoading(true);
		const itemPayload = {
			collectionId: props?.collectionId,
			itemId: props?.itemId,
			operation: 'delete'
		};

		try {
			const request = await callApi('post', 'item', itemPayload);
			if (request?.error || request?.status === 'error') {
				showError(request?.message || request?.error);
				setLoading(false);
			} else {
				props?.onDelete();
			}
		} catch (err) {
			console.error('Error while deleting item', err);
			setLoading(false);
		}
	};

	const onDelete = async () => {
		setDeleteVisible(false);
		await handleDeleteClick();
	};

	const showError = message => {
		notification['error']({
			duration: 10,
			message: 'An error occurred',
			description: message
		});
	};

	return (
		<Fragment>
			{loading ? (
				<Loading />
			) : (
				<div className='nft-card'>
					<div className='card-wrapper'>
						<div className='img-wrapper'>
							<Image
								src={props?.imageUrl}
								alt={props?.itemName}
								fallback={missingImage}
							/>
						</div>
						<div className='info-wrapper'>
							<span className='item-name bold'>
								{isEmpty(props?.itemName) ? 'Not specified' : props?.itemName}
							</span>
							<div className='info-inner'>
								<Space size={5}>
									<Avatar src={props?.imageUrl} size={22} />
									<span className='campaign-name'>{props?.title}</span>
								</Space>
								<DeleteOutlined onClick={() => setDeleteVisible(true)} />
							</div>
						</div>
						<div className='date-wrapper'>
							<Space align='center'>
								{Number(props?.endDate) >= Date.now() ? (
									<Space>
										<span className='info'>Ending</span>
										<span className='date'>
											{new Date(Number(props?.endDate)).toLocaleString(
												'en-us',
												dateOptions
											)}
										</span>
									</Space>
								) : (
									<span className='info'>Ended</span>
								)}
							</Space>
						</div>
					</div>
				</div>
			)}
			<DeleteModal
				visible={deleteVisible}
				onFinish={onDelete}
				onCancel={() => setDeleteVisible(false)}
				itemId={props?.itemId}
			/>
		</Fragment>
	);
};

export default NftCard;
