import React from 'react';
import { Modal } from 'antd';
import { tickIcon, errIcon } from '../';

const ConfirmModal = ({ visible, onFinish, err, message }) => (
	<Modal
		visible={visible}
		okText={!err ? 'Finish' : 'Try again'}
		cancelButtonProps={{ style: { display: 'none' } }}
		okButtonProps={{ className: 'primary-btn' }}
		closable={false}
		destroyOnClose
		onOk={onFinish}
		className='confirm-modal'>
		<div className='confirm-content-wrapper'>
			<img src={!err ? tickIcon : errIcon} alt='modal-status' />
			<div className='confirm-info'>{message}!</div>
		</div>
	</Modal>
);

export default ConfirmModal;
