import React from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import Icon from '@ant-design/icons';
import { WebIcon, TwitterIcon, InstagramIcon } from '.';

const Footer = () => (
	<div className='footer-wrapper'>
		<div className='footer-content'>
			<Space size='middle' direction='vertical' className='description-wrapper'>
				<span className='title'>
					TINT
					<span className='sub-title'>Marketer Portal</span>
				</span>
				<span className='description'>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hac suspendisse lorem
					mauris consectetur arcu interdum sodales ultricies.
				</span>
			</Space>
			<Space size='middle' direction='vertical' className='social-wrapper'>
				<span className='title'>Join the community</span>
				<Space className='social-links' align='center'>
					<Link to={'/web'}>
						<Icon component={() => <WebIcon />} />
					</Link>
					<Link to={'/twitter'}>
						<Icon component={() => <TwitterIcon />} />
					</Link>
					<Link to={'/instagram'}>
						<Icon component={() => <InstagramIcon />} />
					</Link>
				</Space>
			</Space>
			<div className='marketing-portal-wrapper'>
				<Link to={'/marketing-portal/home'}>
					<Space size='middle' direction='vertical'>
						<span className='title'>Want to claim a NFT?</span>
						<span className='description'>
							Visit our main page to claim your NFTs and explore other campaigns!
						</span>
					</Space>
				</Link>
			</div>
		</div>
		<div className='divider' />
		<div className='copyrights-wrapper'>
			<span>2022 IOTA</span>
			<Space size='middle' align='center'>
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.iota.org/privacy-policy'>
					Privacy Policy
				</a>
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.iota.org/connect/contact'>
					Contact Us
				</a>
			</Space>
		</div>
	</div>
);

export default Footer;
