import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AppContext } from "./context/GlobalState";
import { 
  Login,
  Register,
  Home,
  CreateCampaign,
  CampaignDetails,
  CollectibleCampaign,
  UniqueCampaign,
  FungibleCampaign,
  Admin,
  AssignUsers,
  Logout,
  Invite
} from "./pages";

const protectedRoutes = [
  {
    path: `/admin`,
    main: <Admin />,
  },
  {
    path: `/assign`,
    main: <AssignUsers />,
  },
  {
    path: `/home`,
    main: <Home />,
  },
  {
    path: `/create-campaign`,
    main: <CreateCampaign />,
  },
  {
    path: `/campaign/:campaignId`,
    main: <CampaignDetails />,
  },
  {
    path: `/manage/:campaignId`,
    main: <CreateCampaign />,
  },
  {
    path: `/collectible/:campaignId`,
    main: <CollectibleCampaign />,
  },
  {
    path: `/unique/:campaignId`,
    main: <UniqueCampaign />,
  },
  {
    path: `/fungible/:campaignId`,
    main: <FungibleCampaign />,
  }
];

const Router = () => {
  const { isLoggedIn } = useContext(AppContext);

  return (
     <BrowserRouter>
        <Routes>
        <Route exact path='/' element={<Navigate replace to='/register' />} />
          {
            protectedRoutes.map(route => (
              isLoggedIn 
                ? <Route key={route.path} path={route.path} element={route.main} />
                : <Route exact key={route.path} path={route.path} element={<Register />} />
            ))
          }
          <Route path = "/invite/:email/:inviteCode" element={<Invite />} />
          <Route path='/register' element={<Register />} />
				  <Route path='/login' element={<Login />} />
          <Route path = "/logout" element={<Logout />} />
        </Routes>
      </BrowserRouter>
  );
};

export default Router;