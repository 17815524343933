import React, { useEffect, useState } from 'react';
import { Modal, Checkbox } from 'antd';

const CampaignsModal = ({ visible, callback, cancelCallback, collections }) => {
	const [open, setOpen] = useState(visible);
	const [checkedList, setCheckedList] = useState([]);
	const [indeterminate, setIndeterminate] = useState(false);
	const [checkAll, setCheckAll] = useState(false);

	const options = collections?.map(({ collectionName, campaignId }) => ({
		label: collectionName,
		value: campaignId
	}));

	useEffect(() => {
		setOpen(visible);
	}, [visible]);

	const onSubmit = () => {
		callback(checkedList);
		setOpen(false);
		setCheckedList([]);
	};

	const onCancel = () => {
		cancelCallback();
	};

	const onSelect = checkedValues => {
		setCheckedList(checkedValues);
		setIndeterminate(!!checkedValues.length && checkedValues.length < options.length);
		setCheckAll(checkedValues.length === options.length);
	};

	const onCheckAll = e => {
		setCheckedList(e.target.checked ? options?.map(({ value }) => value) : []);
		setIndeterminate(false);
		setCheckAll(e.target.checked);
	};

	return (
		<Modal
			visible={open}
			closable
			okText='Save'
			okButtonProps={{ className: 'primary-btn' }}
			onOk={onSubmit}
			cancelButtonProps={{ style: { display: 'none' } }}
			centered
			title='Select campaigns'
			className='campaigns-modal'
			onCancel={onCancel}>
			<div className='modal-popup'>
				<Checkbox indeterminate={indeterminate} onChange={onCheckAll} checked={checkAll}>
					Select all
				</Checkbox>
				<div className='modal-popup-collections-wrapper'>
					<Checkbox.Group options={options} value={checkedList} onChange={onSelect} />
				</div>
			</div>
		</Modal>
	);
};

export default CampaignsModal;
