import React, { useState } from 'react';
import { Upload, Modal } from 'antd';
import { customAlphabet } from 'nanoid';
import { deleteFile, uploadFile } from '../utils/firebase';

const FileUploader = ({
	path,
	onReady,
	maxLength = 1,
	clearOnReady = false,
	multiple = false,
	imageType = '',
	listType = 'picture-card'
}) => {
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [previewTitle, setPreviewTitle] = useState('');
	const [fileList, setFileList] = useState([]);
	const [fileName, setFileName] = useState('');
	const [itemId, setItemId] = useState('');

	const generateName = file => {
		const nanoid = customAlphabet('ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789', 10);
		const fileName = file?.name;
		const fileExtension = fileName.substr(fileName.indexOf('.'));
		const itemId = nanoid(6);
		setItemId(itemId);
		return itemId + fileExtension;
	};

	const getBase64 = file => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	};

	const handlePreview = async file => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}

		setPreviewVisible(true);
		setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
		setPreviewImage(file.url || file.preview);
	};
	const handleCancel = () => setPreviewVisible(false);

	const handleUpload = async ({ onSuccess, onError, file, onProgress }) => {
		let fileObj = new File([file], file.name, { type: file.type });
		if (maxLength === 1) {
			const name = generateName(file);
			fileObj = new File([file], name, { type: file.type });
			setFileName(name);
		}
		await uploadFile(
			fileObj,
			uploadSuccessCallback,
			path,
			onSuccess,
			onError,
			onProgress
		);
	};

	const handleDeletion = async file => {
		await deleteFile(file?.name, path);
	};

	const uploadSuccessCallback = async () => {
		try {
			return true;
			// eslint-disable-next-line no-unreachable
		} catch (err) {
			console.error('Error while registering activity', err);
		}
	};

	const handleChange = info => {
		setFileList(info?.fileList);
		const files = info?.fileList?.map(file => ({
			name: maxLength ? fileName : file?.name,
			lastModified: file?.lastModified,
			size: file?.size,
			status: file?.status,
			type: file?.type,
			percent: file?.percent,
			url: file?.response,
			path,
			itemId
		}));

		const isDone =
			files?.filter(file => file?.status === 'done' /*|| file?.percent === 100*/)
				?.length === info?.fileList?.length;

		if (isDone) {
			onReady(files);
			clearOnReady && setFileList([]);
		}
	};

	const uploadProps = {
		customRequest: handleUpload,
		onRemove: handleDeletion,
		onPreview: handlePreview,
		onChange: handleChange,
		listType,
		fileList,
		multiple,
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Headers': '*'
		},
		accept: '.png,.jpg,.jpeg,.gif'
	};

	return (
		<div>
			<Upload {...uploadProps}>
				{fileList.length >= maxLength ? null : (
					<>
						{imageType === 'Logo' ? (
							<button type='button' className='primary-btn'>
								ADD
							</button>
						) : (
							<button type='button' className='primary-btn'>
								CHOOSE FILE
							</button>
						)}
					</>
				)}
			</Upload>
			<Modal
				visible={previewVisible}
				title={previewTitle}
				footer={null}
				className='nft-view-modal'
				onCancel={handleCancel}>
				<img alt='' style={{ width: '100%' }} src={previewImage} />
			</Modal>
		</div>
	);
};

export default FileUploader;
