import React, { useEffect, useState, useContext, useCallback } from 'react';
import { notification, Tabs, Table, Avatar, Space, Select } from 'antd';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { AppContext } from '../context/GlobalState';
import callApi from '../utils/callApi';
import { CampaignCard, Layout, Loading } from '../components';

const { TabPane } = Tabs;
const { Option } = Select;

const Home = () => {
	const [currentCampaigns, setCurrentCampaigns] = useState([]);
	const [loading, setLoading] = useState(false);
	const [agency, setAgency] = useState({});
	const [clientColumns, setClientColumns] = useState([]);
	const [clientsData, setClientsData] = useState([]);
	const [filterValue, setFilterValue] = useState();
	const [searchQuery, setSearchQuery] = useState('');
	const [sortValue, setSortValue] = useState('');
	const [, updateState] = useState();
	const { user, collections, campaigns, clients } = useContext(AppContext);

	useEffect(() => {
		setLoading(true);
		let filteredCampaigns = campaigns;

		if (searchQuery) {
			filteredCampaigns = campaigns?.filter(
				campaign =>
					campaign?.collectionName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
					campaign?.collectionId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
					campaign?.collectionDescription
						?.toLowerCase()
						.includes(searchQuery.toLowerCase()) ||
					campaign?.collectionType?.toLowerCase().includes(searchQuery.toLowerCase())
			);
		}

		switch (sortValue) {
			case 'collectionName':
				filteredCampaigns = filteredCampaigns?.sort((a, b) =>
					a?.collectionName.localeCompare(b?.collectionName)
				);
				break;
			case 'startDate':
			default:
				filteredCampaigns = filteredCampaigns?.sort(
					(a, b) => Number(a?.startDate) - Number(b?.startDate)
				);
		}

		setCurrentCampaigns(filteredCampaigns);
		setLoading(false);
		forceUpdate();
	}, [campaigns, searchQuery, filterValue, sortValue]);

	useEffect(() => {
		async function fetchAgency() {
			setLoading(true);
			const response = await callApi('get', `agency?agencyId=${user?.agencyId}`);
			if (!response?.error && response?.status !== 'error') {
				response?.agency && setAgency(response?.agency);
			} else {
				notification['error']({
					duration: 10,
					message: 'Agency fetch error',
					description: response?.message
				});
			}
			setLoading(false);
		}

		user?.agencyId && user?.role && fetchAgency();
	}, [JSON.stringify(user)]); // eslint-disable-line

	useEffect(() => {
		async function listClients() {
			setLoading(true);
			if (user?.role) {
				try {
					const response = await callApi('get', 'clients');
					if (response?.error || response?.status === 'error') {
						console.error('Error while loading clients', response?.error);
						setLoading(false);
					} else {
						const columns = Object.keys(response?.clients?.[0])?.map(key => ({
							title: key.replace('client', ''),
							dataIndex: key,
							key,
							sorter: (a, b) => a?.clientName.localeCompare(b?.clientName)
						}));
						setClientColumns(columns);
						setClientsData(response?.clients);
						setLoading(false);
					}
				} catch (err) {
					console.error('Error while loading clients', err);
					setLoading(false);
				}
			}
		}

		listClients();
	}, [clients, user?.role]); // eslint-disable-line

	const forceUpdate = useCallback(() => updateState({}), []);

	const handleChange = value => {
		setFilterValue(value);
	};

	const ELEMENT_FILTER = {
		undefined: element => element,
		active: element => Number(element?.endDate) >= Date.now(),
		expired: element => Number(element?.endDate) < Date.now()
	};

	return (
		<Layout>
			{loading ? (
				<Loading />
			) : (
				<div className='homepage-wrapper'>
					<div className='sub-header-profile'>
						<Space size='large' align='center'>
							<Avatar
								src={agency?.agencyLogoUrl}
								icon={<UserOutlined style={{ fontSize: '80px' }} />}
								size={134}
							/>
							<Space size='middle' direction='vertical' align='start'>
								<span className='username'>{agency?.agencyName}</span>
								<div className='campaigns-info-wrapper'>
									<div className='info-box'>
										<span className='data'>{campaigns?.length}</span>
										<span className='label'>Campaigns</span>
									</div>
									<div className='info-box'>
										<span className='data'>
											{
												campaigns?.filter(
													campaign => Number(campaign?.endDate) >= Date.now()
												)?.length
											}
										</span>
										<span className='label'>Active</span>
									</div>
									<div className='info-box'>
										<span className='data'>
											{
												campaigns?.filter(
													campaign => Number(campaign?.endDate) < Date.now()
												)?.length
											}
										</span>
										<span className='label'>Ended</span>
									</div>
								</div>
							</Space>
						</Space>
					</div>
					<div className='campaigns-tab-wrapper'>
						<Tabs centered defaultActiveKey='campaigns'>
							<div className='options-wrapper'>
								<Select
									showArrow={false}
									className='selector-wrapper'
									placeholder='Status'
									allowClear
									onChange={handleChange}>
									<Option className='tertiary-btn' value='active'>
										Active
									</Option>
									<Option className='tertiary-btn' value='expired'>
										Expired
									</Option>
								</Select>
								<div className='search-box-wrapper'>
									<div className='input-search'>
										<input
											id='search-box'
											onChange={e => setSearchQuery(e.target.value)}
											placeholder='Search this page'
											type='text'
											className='search-box'
										/>
										<label htmlFor='search-box'>
											<SearchOutlined className='search-icon' />
										</label>
									</div>
								</div>
								<Select
									style={{ width: 260 }}
									placeholder='Sort by'
									onChange={setSortValue}>
									<Option value='startDate'>Starting date</Option>
									<Option value='collectionName'>Campaign name</Option>
								</Select>
							</div>
							<TabPane tab='Campaigns' key='campaigns'>
								<div className='campaign-cards-wrapper'>
									{currentCampaigns?.filter(ELEMENT_FILTER[filterValue])?.map(campaign => (
										<CampaignCard
											key={campaign?.campaignId}
											campaign={campaign}
											collections={collections}
											clients={clients}
										/>
									))}
								</div>
							</TabPane>
							<TabPane tab='Clients' key='clients'>
								<Table
									columns={clientColumns}
									dataSource={clientsData}
									rowKey={record => record?.clientId}
								/>
							</TabPane>
						</Tabs>
					</div>
				</div>
			)}
		</Layout>
	);
};

export default Home;
