import React, { Fragment } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import callApi from '../utils/callApi';
import { isValidUrl, dateOptions } from '../utils/helpers';
import { notification, Tabs, Dropdown, Menu, Avatar, Space, Select } from 'antd';
import {
	NftCard,
	StatisticsCard,
	WebIconOutlined,
	TwitterIconOutlined,
	InstagramIconOutlined,
	Layout,
	Loading,
	DownloadCodesModal
} from '../components';

const { TabPane } = Tabs;
const { Option } = Select;

const CampaignDetails = () => {
	const [collection, setCollection] = useState({});
	const [campaign, setCampaign] = useState({});
	const [filterValue, setFilterValue] = useState();
	const [searchQuery, setSearchQuery] = useState('');
	const [loading, setLoading] = useState(true);
	const [downloadVisible, setDownloadVisible] = useState(false);
	const [items, setItems] = useState([]);
	const params = useParams();
	const navigate = useNavigate();
	const mockData = [
		{
			title: 'Collectible #1',
			label: 'NFTS claimed',
			claimed: '455/100 (4.5%)',
			rarityLabel: 'Rarity',
			statisticsLabel: 'Statistic',
			rarity: '6%',
			statistic: 'XY'
		}
	];

	const handleMenuClick = () => {};

	const claimMenu = (
		<Menu className='dropdown-wrapper' onClick={handleMenuClick}>
			<Menu.Item key='claim-1'>
				<span className='item'>Test </span>
			</Menu.Item>
		</Menu>
	);

	const handleChange = value => {
		setFilterValue(value);
	};

	const ELEMENT_FILTER = {
		undefined: element => element,
		isClaimed: element => element?.isClaimed,
		notClaimed: element => !element?.isClaimed
	};

	useEffect(() => {
		fetchCampaign();
	}, []); // eslint-disable-line

	useEffect(() => {
		campaign?.collectionId && fetchItems(campaign?.collectionId);
	}, [campaign?.collectionId]); // eslint-disable-line

	const fetchItems = async (collectionId = campaign?.collectionId) => {
		const response = await callApi('get', `items?collectionId=${collectionId}`);
		if (!response?.error && response?.status !== 'error') {
			response?.items && setItems(response?.items);
		} else {
			notification['error']({
				duration: 10,
				message: 'An error occurred',
				description: response?.message
			});
		}
	};

	const fetchCollection = async (collectionId = campaign?.collectionId) => {
		const response = await callApi('get', `collection?collectionId=${collectionId}`);
		if (!response?.error && response?.status !== 'error') {
			response?.collection && setCollection(response?.collection);
		} else {
			notification['error']({
				duration: 10,
				message: 'An error occurred',
				description: response?.message
			});
		}
	};

	const fetchCampaign = async () => {
		const campaignsResponse = await callApi(
			'get',
			`campaign?campaignId=${params?.campaignId}`
		);
		if (!campaignsResponse?.error && campaignsResponse?.status !== 'error') {
			const campaign = campaignsResponse?.campaign;
			if (campaign.campaignId === params.campaignId) {
				setCampaign(campaign);
				if (campaign?.collectionId) {
					await fetchCollection(campaign?.collectionId);
					await fetchItems(campaign?.collectionId);
				}
			}
		} else {
			notification['error']({
				duration: 10,
				message: 'An error occurred',
				description: campaignsResponse?.message
			});
		}
		setLoading(false);
	};

	const processCampaignLockRequest = async () => {
		setLoading(true);
		try {
			const payload = {
				...campaign,
				isLocked: true,
				operation: 'update'
			};
			const response = await callApi('post', 'campaign', payload);
			if (response?.error || response?.status === 'error') {
				showError(response?.message || response?.error);
			} else {
				showSuccess();
				setCampaign({ ...campaign, isLocked: true });
			}
			setLoading(false);
		} catch (err) {
			console.error('processCampaignLockRequest Error', err);
		}
	};

	const processMintRequest = async () => {
		setLoading(true);
		try {
			const payload = {
				...campaign,
				mintRequested: true,
				operation: 'update'
			};
			const response = await callApi('post', 'campaign', payload);
			if (response?.error || response?.status === 'error') {
				showError(response?.message || response?.error);
			} else {
				showSuccess();
				setCampaign({ ...campaign, mintRequested: true });
			}
			setLoading(false);
		} catch (err) {
			console.error('processCampaignLockRequest Error', err);
		}
	};

	const handleAddNFTClicked = () => {
		if (collection?.collectionType === 'fungible') {
			navigate(`/fungible/${params.campaignId}`);
		}
		if (collection?.collectionType === 'collectible') {
			navigate(`/collectible/${params.campaignId}`);
		}
		if (collection?.collectionType === 'unique') {
			navigate(`/unique/${params.campaignId}`);
		}
	};

	const showError = message => {
		notification['error']({
			duration: 10,
			message: 'An error occurred',
			description: message
		});
	};

	const showSuccess = () => {
		notification['success']({
			duration: 10,
			message: 'Success',
			description: 'Operation completed successfully'
		});
	};

	return (
		<Layout>
			{loading ? (
				<Loading />
			) : (
				<Fragment>
					<div className='sub-header-campaign'>
						<img
							alt={`${collection?.collectionName}-blur`}
							src={collection?.collectionBannerUrl}
							className='banner-blur-bg'
						/>
						<img
							alt={collection?.collectionName}
							src={collection?.collectionBannerUrl}
							className='campaign-banner-image'
						/>
					</div>
					<div className='campaign-page-wrapper'>
						<div className='campaign-info-wrapper'>
							<Avatar
								className='avatar-wrapper'
								src={collection?.collectionLogoUrl}
								size={155}
							/>
							<div className='info-2'>
								<div className='info-inner'>
									<Space size={10} direction='vertical'>
										<span className='collection-name bold'>
											{collection?.collectionName}
										</span>
										<div className='colletion-type bold'>
											{collection?.collectionType}
										</div>
									</Space>
									<Space size='large'>
										<div className='date-wrapper'>
											{new Date(Number(campaign?.endDate)) > new Date() ? (
												<Space>
													<span className='info'>Ending</span>
													<span className='date'>
														{new Date(Number(campaign?.endDate)).toLocaleString(
															'en-us',
															dateOptions
														)}
													</span>
												</Space>
											) : (
												<span className='info'>Ended</span>
											)}
										</div>
										<Space size='middle'>
											{campaign?.websiteUrl && isValidUrl(campaign?.websiteUrl) && (
												<a
													target='_blank'
													rel='noopener noreferrer'
													href={campaign?.websiteUrl}>
													<Icon component={() => <WebIconOutlined />} />
												</a>
											)}
											{campaign?.twitterUrl && isValidUrl(campaign?.twitterUrl) && (
												<a
													target='_blank'
													rel='noopener noreferrer'
													href={campaign?.twitterUrl}>
													<Icon component={() => <TwitterIconOutlined />} />
												</a>
											)}
											{campaign?.instagramUrl && isValidUrl(campaign?.instagramUrl) && (
												<a
													target='_blank'
													rel='noopener noreferrer'
													href={campaign?.instagramUrl}>
													<Icon component={() => <InstagramIconOutlined />} />
												</a>
											)}
										</Space>
									</Space>
								</div>
								<div className='description'>{collection?.collectionDescription}</div>
							</div>
						</div>
						<div className='navigation-buttons'>
							{items?.length > 0 ? (
								<button
									onClick={() => setDownloadVisible(true)}
									className='secondary-btn'>
									Download Codes
								</button>
							) : null}
							{!campaign?.isLocked ? (
								<button
									onClick={() => navigate(`/manage/${params.campaignId}`)}
									className='secondary-btn'>
									Manage Campaign
								</button>
							) : null}
							{!campaign?.isLocked ? (
								<button onClick={processCampaignLockRequest} className='secondary-btn'>
									Lock-in campaign
								</button>
							) : null}
							{campaign?.isLocked && !campaign?.mintRequested ? (
								<button onClick={processMintRequest} className='secondary-btn'>
									Request Mint
								</button>
							) : null}
							{!campaign?.isLocked ? (
								<button onClick={handleAddNFTClicked} className='secondary-btn'>
									Add NFT's
								</button>
							) : null}
						</div>
						<Tabs className='campaign-tabs' centered defaultActiveKey='statistics'>
							<TabPane tab='Statistics' key='statistics'>
								<div className='campaign-details-container'>
									<div className='campaign-details-box'>
										<span className='label'>NFTS claimed</span>
										<span className='value'>455/10.000 (4.5%)</span>
									</div>
									<div className='campaign-details-box'>
										<span className='label'>Claim/Sign in ratio</span>
										<span className='value'>20%</span>
									</div>
									<div className='campaign-details-box'>
										<span className='label'>Set completed by NFT holders</span>
										<span className='value'>5%</span>
									</div>
									<div className='campaign-details-box'>
										<span className='label'>Unique owners</span>
										<span className='value'>200</span>
									</div>
									<div className='campaign-details-box'>
										<span className='label'>NFTs burned</span>
										<span className='value'>10/10.000 (0.1%)</span>
									</div>
									<div className='campaign-details-box'>
										<span className='label'>Campaign views</span>
										<span className='value'>10.000</span>
									</div>
									<div className='stats-wrapper'>
										{items.length > 0 ? (
											items?.map(item => (
												<StatisticsCard
													item={item}
													key={item.itemId}
													mockData={mockData}
												/>
											))
										) : (
											<div className='no-nfts-case'>
												<Space size='large' direction='vertical' align='center'>
													<span>This campaign does not contain NFT's yet.</span>
													<button onClick={handleAddNFTClicked} className='primary-btn'>
														ADD NFT's
													</button>
												</Space>
											</div>
										)}
									</div>
								</div>
							</TabPane>
							<TabPane tab='All items' key='all-items'>
								<div className='options-wrapper'>
									<Select
										showArrow={false}
										className='selector-wrapper'
										placeholder='Status'
										allowClear
										onChange={handleChange}>
										<Option className='tertiary-btn' value='isClaimed'>
											Claimed
										</Option>
										<Option className='tertiary-btn' value='notClaimed'>
											Not Claimed
										</Option>
									</Select>
									<button className='tertiary-btn'>Rarity</button>
									<div className='search-box-wrapper'>
										<div className='input-search'>
											<input
												id='search-box'
												onChange={e => setSearchQuery(e.target.value)}
												placeholder='Search'
												type='text'
												className='search-box'
											/>
											<label htmlFor='search-box'>
												<SearchOutlined className='search-icon' />
											</label>
										</div>
									</div>
									<Dropdown className='dropdown-wrapper' overlay={claimMenu}>
										<button className='tertiary-btn-dropdown'>
											Latest Claim <DownOutlined />
										</button>
									</Dropdown>
								</div>
								<div className='nft-card-list-container'>
									{isEmpty(items) ? (
										<div className='empty-items'>No items to display...</div>
									) : null}
									{items
										?.filter(item => {
											if (searchQuery === '') {
												return true;
											} else if (
												item?.itemName?.toLowerCase().includes(searchQuery.toLowerCase())
											) {
												return true;
											}
											return false;
										})
										?.filter(ELEMENT_FILTER[filterValue])
										?.map(item => (
											<NftCard
												key={item?.itemId}
												title={collection?.collectionName}
												itemName={item?.itemName}
												imageUrl={item?.imageUrl}
												itemId={item?.itemId}
												collectionId={item?.collectionId}
												endDate={campaign?.endDate}
												onDelete={fetchItems}
											/>
										))}
								</div>
							</TabPane>
						</Tabs>
					</div>
				</Fragment>
			)}
			<DownloadCodesModal
				onCancel={() => setDownloadVisible(false)}
				visibleD={downloadVisible}
				items={items}
			/>
		</Layout>
	);
};

export default CampaignDetails;
