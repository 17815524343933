import { createContext, useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { notification } from "antd";
import callApi from "../utils/callApi";
import { auth, logout } from "../utils/firebase";
import { buildUserPayload } from "../utils/helpers";
export const AppContext = createContext({});

const GlobalState = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [collections, setCollections] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState([]);
  const [role, setRole] = useState("");

  async function readSession() {
    const user = await window.sessionStorage.getItem(
      `firebase:authUser:${process.env.REACT_APP_API_KEY}:[DEFAULT]`
    );
    if (user) {
      const storedUser = await localStorage.getItem("user");
      console.log("setLoggedIn 1", storedUser);
      storedUser && !isEmpty(storedUser) && setUser(JSON.parse(storedUser));
      console.log("setLoggedIn 2", JSON.parse(storedUser));
      setLoggedIn(true);
    }
  }

  useEffect(() => {
    try {
      setLoading(true);
      readSession();
      auth.onAuthStateChanged(async (firebaseUser) => {
        try {
          setLoading(false);
          const userFromRedirect = await auth
            .getRedirectResult()
            .catch((err) => {
              setErrors([err?.message]);
              setLoading(false);
            });
          if (!isEmpty(userFromRedirect?.user)) {
            await handleSignUp(userFromRedirect);
          } else if (!isEmpty(firebaseUser)) {
            await handleSignUp({ user: firebaseUser });
          }

          if (!firebaseUser) {
            await localStorage.clear();
            setUser({});
            setLoggedIn(false);
          }
        } catch (error) {
          setLoading(false);
          console.log("onAuthStateChanged", error);
        }
      });
    } catch (error) {
      setLoading(false);
      console.error("Authentication", error);
    }
    isLoggedIn && user?.role && fetchClients() && fetchCampaigns() && fetchCollections()  ;
  }, [isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  const showError = (message) => {
    notification["error"]({
      duration: 10,
      message: "An error occurred",
      description: message,
    });
  };

  const fetchCampaigns = async () => {
    const response = await callApi("get", "campaigns");
    if (!response?.error && response?.status !== "error") {
      response?.campaigns && setCampaigns(response?.campaigns);
    } else {
      notification["error"]({
        duration: 10,
        message: "Campaigns fetch error",
        description: response?.message,
      });
    }
  };

  const fetchCollections = async () => {
    const response = await callApi("get", "collections");
    if (!response?.error && response?.status !== "error") {
      response?.collections && setCollections(response?.collections);
    } else {
      notification["error"]({
        duration: 10,
        message: "An error occurred",
        description: response?.message,
      });
    }
  };

  const fetchClients = async () => {
    const response = await callApi("get", "clients");
    if (!response?.error && response?.status !== "error") {
      response?.clients && setClients(response?.clients);
    } else {
      notification["error"]({
        duration: 10,
        message: "Clients fetch error",
        description: response?.message,
      });
    }
  };

  const handleSignUp = async (userData, params = {}) => {
    try {
      setLoading(true);
      const role = await localStorage.getItem("role");

      if (!isEmpty(userData?.user)) {
        let payload;
        if (userData?.additionalUserInfo) {
          payload = buildUserPayload({
            userData,
            role,
            ...params,
          });
        } else {
          const storedUser = await localStorage.getItem("user");
          if (storedUser && !isEmpty(storedUser)) {
            payload = storedUser && JSON.parse(storedUser);
          }
        }

        let idTokenResult;
        if (userData?.additionalUserInfo?.isNewUser) {
          const userResult = await callApi("put", "user", payload);
          userResult?.status !== "success" &&
            setErrors([...errors, userResult?.error]);
          payload = userResult?.userFirebase;
        } else {
          idTokenResult = await auth?.currentUser?.getIdTokenResult();
          const roleFromClaims = idTokenResult?.claims?.role;

          if (payload && !isEmpty(payload) && roleFromClaims) {
            await localStorage.setItem("role", roleFromClaims);
            setRole(roleFromClaims);
            payload.role = roleFromClaims;
            payload.signInProvider = idTokenResult?.signInProvider;
          }
        }
        payload &&
          !isEmpty(payload) &&
          (await localStorage.setItem("user", JSON.stringify(payload)));
        setUser(payload);
        setLoading(false);
        checkStoredUser(payload);
      }
      setLoggedIn(!isEmpty(userData?.user));
    } catch (error) {
      console.error(error);
    }
  };

  const checkStoredUser = async (payload) => {
    try {
      let storedUserRecordResponse = await callApi("get", "user");
      let storedUserRecord = storedUserRecordResponse?.userFirebase;
      if (payload && !isEmpty(payload) && !storedUserRecord) {
        const userResult = await callApi("put", "user", payload);
        userResult?.status !== "success" &&
          setErrors([...errors, userResult?.error]);
        storedUserRecord = userResult?.userFirebase;
      }

      const userObj = {
        ...payload,
        ...storedUserRecordResponse?.user,
        ...storedUserRecordResponse?.userFirebase,
      };
      setUser(userObj);
      userObj &&
        !isEmpty(userObj) &&
        (await localStorage.setItem("user", JSON.stringify(userObj)));
    } catch (error) {
      console.error(error);
    }
  };

  const userLogout = async () => {
    logout();
    setLoggedIn(false);
    setUser({});
    setErrors([]);
    setCollections([]);
    setCampaigns([]);
    setClients([]);
    setRole("");
    setLoading(false);
  };

  return (
    <AppContext.Provider
      value={{
        isLoggedIn,
        setLoggedIn,
        user,
        setUser,
        role,
        setRole,
        loading,
        setLoading,
        errors,
        setErrors,
        handleSignUp,
        userLogout,
        campaigns,
        setCampaigns,
        collections,
        setCollections,
        clients,
        fetchClients,
        fetchCampaigns,
        fetchCollections,
        showError
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default GlobalState;
