import JSZip from 'jszip';
import FileSaver from 'file-saver';

const qrGenerate = async () => { 
  try {
    // 1. obtain canvas Elements
    const allCanvas = document.querySelectorAll('.qrcode-container > .qrcode');
    if (!allCanvas.length) {
      return;
    }

    // 2. Each one canvas Generate base64 Format
    const zip = new JSZip().folder('qrcodes');
    for await (const canvas of allCanvas) {
      const item = {
        data: canvas.toDataURL().substring(22), // substring(22) It's getting rid of it base64 Head
        name: canvas.getAttribute('name'),
      };
      await zip.file(item?.name + '.png', item?.data, { base64: true });
    }

    // 3. Save in binary form, and through file-saver Download to local
    zip.generateAsync({ type: 'blob', compression: 'DEFLATE' }).then(
      blob => FileSaver.saveAs(blob, 'qrcodes.zip'),
      error => console.error('qrGenerate error', error)
    );
  } catch (err) {
    console.error(err);
  }
}

export {
  qrGenerate,
};