import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/GlobalState";

const Login = () => {
  const navigate = useNavigate();
  const { userLogout } = useContext(AppContext);

  useEffect(() => {
    console.log('Logout');
    userLogout();
    navigate('/');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <div />
}

export default Login;
