import React from 'react';
import { Modal } from 'antd';
import QRCode from 'qrcode.react';
import { CSVLink } from 'react-csv';
import zipcelx from 'zipcelx';
import { qrGenerate } from '../../utils/zipQRCodes';
import { logo } from '../';

const DownloadCodesModal = ({ visibleD, items, onCancel }) => {
	const data = items?.map(item => {
		const {
			itemId,
			itemName,
			itemDescription,
			itemQuantity,
			itemType,
			campaignId,
			claimCode,
			collectionId,
			fileName
		} = item;
		const qrPayload = `${collectionId}/${itemId}/${claimCode}`;
		return {
			itemId,
			itemName,
			itemDescription,
			itemQuantity,
			itemType,
			campaignId,
			collectionId,
			claimCode,
			qrPayload,
			fileName
		};
	});

	const headers = [
		{ label: 'NFT ID', key: 'itemId', value: 'NFT ID', type: 'string' },
		{ label: 'Name', key: 'itemName', value: 'Name', type: 'string' },
		{
			label: 'Description',
			key: 'itemDescription',
			value: 'Description',
			type: 'string'
		},
		{ label: 'Quantity', key: 'itemQuantity', value: 'Quantity', type: 'string' },
		{ label: 'Type', key: 'itemType', value: 'Type', type: 'string' },
		{ label: 'Campaign ID', key: 'campaignId', value: 'Campaign ID', type: 'string' },
		{
			label: 'Collection ID',
			key: 'collectionId',
			value: 'Collection ID',
			type: 'string'
		},
		{ label: 'Claim code', key: 'claimCode', value: 'Claim code', type: 'string' },
		{
			label: 'QR code content',
			key: 'qrPayload',
			value: 'QR code content',
			type: 'string'
		},
		{
			label: 'Image/Video file name',
			key: 'fileName',
			value: 'Image/Video file name',
			type: 'string'
		}
	];

	const onDownloadZip = async () => {
		await qrGenerate();
	};

	const onDownloadXLSX = () => {
		try {
			const rows = data?.map(row =>
				Object.keys(data?.[0])?.map(entry => ({
					value: entry === 'itemQuantity' ? Number(row[entry]) : row[entry],
					type: entry === 'itemQuantity' ? 'number' : 'string'
				}))
			);

			const config = {
				filename: 'redeem-codes',
				sheet: { data: [headers, ...rows] }
			};

			zipcelx(config);
		} catch (error) {
			console.log('Error generating XLSX file', error);
		}
	};

	return (
		<Modal
			onCancel={onCancel}
			visible={visibleD}
			title='Download codes'
			footer={null}
			centered
			className='download-modal'>
			<div className='download-content-wrapper'>
				<span className='download-description'>
					Print/issue redeemable (QR) codes for all NFTs from this campaign. Each download
					contains 3 files: Sorted by rarity (low to high), by category of randomized.
				</span>
				<div className='btns-wrapper'>
					<button onClick={() => onDownloadZip()} className='secondary-btn'>
						QR (ZIP)
					</button>
					<CSVLink
						data={data}
						headers={headers}
						filename='redeem-codes.csv'
						target='_blank'>
						<button className='secondary-btn'>CSV</button>
					</CSVLink>
					<button onClick={() => onDownloadXLSX()} className='secondary-btn'>
						XLSX
					</button>
				</div>
				<div className='qrcode-container'>
					{items?.map(item => (
						<QRCode
							key={item?.itemId}
							bgColor='#ffffff'
							fgColor='#000000'
							renderAs='canvas'
							className='qrcode'
							name={item?.itemId}
							value={`${item?.collectionId}/${item?.itemId}/${item?.claimCode}`}
							size={320}
							level='H'
							imageSettings={{
								src: logo,
								x: null,
								y: null,
								height: 70,
								width: 70,
								excavate: true
							}}
						/>
					))}
				</div>
			</div>
		</Modal>
	);
};

export default DownloadCodesModal;
