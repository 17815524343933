const buildUserPayload = ({ userData, role, firstName = null, lastName = null }) => {
  const provider = userData?.additionalUserInfo?.providerId;
  if (provider === 'google.com') {
    const profile = userData?.additionalUserInfo?.profile;
    return {
      role,
      firstName: profile?.given_name,
      lastName: profile?.family_name,
      email: profile?.email,
      displayName: profile?.name,
      uid: userData?.user?.uid,
      language: profile?.locale,
      photoURL: profile?.picture,
    }
  } else if (provider === 'microsoft.com') {
    const profile = userData?.additionalUserInfo?.profile;
    return {
      role,
      firstName: profile?.givenName,
      lastName: profile?.surname,
      email: userData?.user?.email,
      displayName: profile?.displayName,
      uid: userData?.user?.uid,
      language: userData?.user?.ya,
      photoURL: userData?.user?.photoURL,
    }
  } else {
    if (firstName && lastName) {
      const userObj = {
        role,
        firstName,
        lastName,
        email: userData?.user?.email,
        displayName: `${firstName} ${lastName}`,
        uid: userData?.user?.uid,
        language: userData?.user?.ya,
        photoURL: null
      }
      return userObj;
    } else if (userData?.user?.displayName) {
      const [first, last] = userData?.user?.displayName.split(' ');
      return {
        role,
        firstName: first,
        lastName: last,
        email: userData?.user?.email,
        displayName: userData?.user?.displayName,
        uid: userData?.user?.uid,
        language: userData?.user?.ya,
        photoURL: null
      }
    }
  }
};

const isValidUrl = url => {
  const urlRegex = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  const adjustedUrl = url.replace(/%2F/gi, '/');
  return urlRegex.test(adjustedUrl); 
}

const dateOptions = {
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit'
};

export {
  buildUserPayload,
  isValidUrl,
  dateOptions
};