const sortByValue = (items, sortBy, searchQuery = '') => {
  let result = items;

  if (searchQuery) {
    result = items?.filter(item =>
      item?.displayName?.toLowerCase().includes(searchQuery?.toLowerCase()) 
      || item?.email?.toLowerCase().includes(searchQuery?.toLowerCase()) 
    )
  }

  switch (sortBy) {
    case 'name':
      result = result.sort((x, y) => {
        const a = x?.displayName?.toLowerCase() || 'z';
        const b = y?.displayName?.toLowerCase() || 'z';
        return a === b ? 0 : a > b ? 1 : -1;
      });
      break;

    case 'email':
      result = result.sort((x, y) => {
        const a = x?.email.toLowerCase() || 'z';
        const b = y?.email.toLowerCase() || 'z';
        return a === b ? 0 : a > b ? 1 : -1;
      });
      break;

    case 'invite':
    default:
      result = result.sort((x, y) => {
        const a = x?.inviteTimestamp || Math.max();
        const b = y?.inviteTimestamp || Math.max();
        // const a = new Date(x?.inviteTimestamp);
        // const b = new Date(y?.inviteTimestamp);
        return b - a;
      });
  }


  return result;
}

export default sortByValue;