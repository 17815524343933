import React, { useState, useEffect, useCallback, useContext } from 'react';
import { customAlphabet } from 'nanoid';
import { Form, notification, Space } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../context/GlobalState';
import { Layout, FileUploader, CollectionsNftCard, Loading } from '../components';
import callApi from '../utils/callApi';

const FungibleCampaign = () => {
	const navigate = useNavigate();
	const { campaignId } = useParams();
	const [campaign, setCampaign] = useState({});
	const [loading, setLoading] = useState(false);
	const [items, setItems] = useState({});
	const [, updateState] = useState();
	const [addNftForm] = Form.useForm();
	const [collectionName, setCollectionName] = useState('');
	const params = useParams();
	const { collections } = useContext(AppContext);

	useEffect(() => {
		setLoading(true);
		fetchCampaign();
		const name = collections?.find(
			collection => collection?.campaignId === params?.campaignId
		);
		setCollectionName(name);
		setLoading(false);
	}, []); // eslint-disable-line

	const forceUpdate = useCallback(() => updateState({}), []);

	const getItemId = () => {
		const nanoid = customAlphabet('ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789', 10);
		const itemId = nanoid(6);
		return itemId;
	};

	const fetchCampaign = async () => {
		setLoading(true);
		const response = await callApi('get', `campaign?campaignId=${campaignId}`);
		if (!response?.error && response?.status !== 'error') {
			response?.campaign && setCampaign(response?.campaign);
		} else {
			notification['error']({
				duration: 10,
				message: 'An error occurred',
				description: response?.message
			});
		}
		setLoading(false);
	};

	const showError = message => {
		notification['error']({
			duration: 10,
			message: 'An error occurred',
			description: message
		});
	};

	const handleSaveRequest = async mintRequested => {
		setLoading(true);
		try {
			const response = await callApi('put', 'items', {
				items: Object.values(items),
				type: 'fungible',
				mintRequested
			});
			if (response?.error || response?.status === 'error') {
				setLoading(false);
				showError(response?.message || response?.error);
			} else {
				setLoading(false);
				navigate(`/campaign/${campaignId}`);
			}
		} catch (err) {
			setLoading(false);
			console.error('Error while creating NFTs', err);
		}
	};

	const removeItem = itemId => {
		delete items?.[itemId];
		setItems(items);
		forceUpdate();
	};

	const updateItem = updatedItem => {
		updatedItem?.itemId && setItems({ ...items, [updatedItem?.itemId]: updatedItem });
	};

	const addItems = async files => {
		const newItems = { ...items };
		for await (const file of files) {
			const item = await addItem(file);
			newItems[item.itemId] = item;
		}
		setItems(newItems);
	};

	const addItem = ({ name, url }) => {
		const { agencyId, clientId, collectionId } = campaign;
		const itemId = getItemId();
		const itemPayload = {
			imageUrl: url,
			fileName: name,
			itemType: 'fungible',
			campaignId,
			itemId,
			clientId,
			agencyId,
			collectionId,
			isClaimed: false,
			itemName: '',
			itemDescription: '',
			itemQuantity: 0
		};

		return itemPayload;
	};

	const onFinish = async () => {
		const formValues = addNftForm?.getFieldsValue();
		Object.keys(items)?.forEach(key => {
			const item = items?.[key];
			item.itemName = formValues?.[`name-${key}`];
			item.itemDescription = formValues?.[`description-${key}`];
			item.itemQuantity = formValues?.[`quantity-${key}`];
		});
		setItems(items);
		await handleSaveRequest();
	};

	return (
		<Layout>
			{loading ? (
				<Loading />
			) : (
				<div className='fungible-campaign-wrapper'>
					<Space className='header-container' direction='vertical' size={12}>
						<span className='header bold'>Fungible Collection</span>
						<span className='sub-header'>
							<span className='label'>Campaign name:</span> {collectionName}
						</span>
					</Space>
					<div className='upload-wrapper'>
						<Space align='start' direction='vertical' size={8} className='upload-info'>
							<span className='title bold'>Upload Files *</span>
							<span className='description'>PNG, JPEG, GIFs and MP4 supported.</span>
						</Space>
						<div className='image-uploader-wrapper'>
							<FileUploader
								path={`collections/${campaign?.collectionId}/fungible/`}
								onReady={addItems}
								maxLength={20}
								multiple
								clearOnReady
							/>
						</div>
					</div>
					<div className='uploaded-wrapper'>
						{Object.keys(items)?.length ? (
							<label className='header bold'>Uploaded</label>
						) : null}
						<Form
							form={addNftForm}
							size='large'
							layout='horizontal'
							name='upload-item-form'
							hideRequiredMark
							colon={false}
							onFinish={onFinish}
							className='upload-form'>
							{Object.values(items)?.map(item => (
								<CollectionsNftCard
									key={item?.itemId}
									item={item}
									updateItem={updateItem}
									removeItem={removeItem}
								/>
							))}
							<div className='submit-buttons'>
								<Space size={15}>
									<button type='submit' className='primary-btn'>
										Save Draft
									</button>
									<button type='submit' className='primary-btn'>
										Add to collection
									</button>
								</Space>
							</div>
						</Form>
					</div>
				</div>
			)}
		</Layout>
	);
};

export default FungibleCampaign;
