import React from 'react';
import { Form, Image, Input, InputNumber, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { missingImage } from '.';

const CollectionsNftCard = ({ item, updateItem, removeItem, pathName }) => {
	const { Option } = Select;

	return (
		<div
			className={
				pathName === 'collectible'
					? 'collectible-nft-container'
					: 'fungible-nft-container'
			}>
			<div className='fungible-image-container'>
				<Image
					name={`imageUrl-${item?.itemId}`}
					src={item?.imageUrl}
					alt={item?.itemName}
					fallback={missingImage}
				/>
			</div>
			<div className='fungible-form'>
				<Form.Item
					rules={[{ required: true, message: 'Please provide NFT name!' }]}
					name={`name-${item?.itemId}`}
					label='Name'
					onChange={e => updateItem(item?.itemId, 'itemName', e?.target?.value)}>
					<Input className='input-nft-name' placeholder='Test NFT' />
				</Form.Item>
				<Form.Item
					name={`description-${item?.itemId}`}
					label='Description'
					onChange={e => updateItem(item?.itemId, 'itemDescription', e?.target?.value)}>
					<Input placeholder='This NFT is very unique' />
				</Form.Item>
				{pathName === 'collectible' ? (
					<Form.Item
						name={`type-${item?.itemId}`}
						label='Type'
						onChange={value => updateItem(item?.itemId, 'itemType', value)}>
						<Select defaultValue='collectible'>
							<Option className='collectible-option' value='collectible'>
								Collectible
							</Option>
							<Option className='collectible-option' value='reward'>
								Reward
							</Option>
						</Select>
					</Form.Item>
				) : null}
				<Form.Item
					rules={[{ required: true, message: 'Please provide minting quantity!' }]}
					name={`quantity-${item?.itemId}`}
					label='Minting quantity'
					onChange={e => updateItem(item?.itemId, 'itemQuantity', e?.target?.value)}>
					<InputNumber min={1} />
				</Form.Item>
			</div>
			<div className='close-button'>
				<CloseOutlined onClick={() => removeItem(item?.itemId)} />
			</div>
		</div>
	);
};

export default CollectionsNftCard;
