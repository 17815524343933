import React, { useEffect, useContext } from 'react';
import { Modal, Form, Input } from 'antd';
import { AppContext } from '../../context/GlobalState';

const CreateClientModal = ({ visible, loading, err, onCreate, onCancel }) => {
	const { fetchClients, clients } = useContext(AppContext);
	const [form] = Form.useForm();

	useEffect(() => {
		fetchClients();
	}, [err]); // eslint-disable-line

	const checkClientName = (_, value) => {
		const name = clients?.find(
			client => client?.clientName?.toLowerCase() === value?.toLowerCase()
		);
		if (name) {
			return Promise.reject(new Error('Indicated client name is already taken!'));
		} else {
			return Promise.resolve();
		}
	};

	return (
		<Modal
			visible={visible}
			title='Create a new client'
			okText='Create client'
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ className: 'primary-btn' }}
			onCancel={onCancel}
			forceRender
			confirmLoading={loading}
			className='input-modal'
			onOk={() => {
				form
					.validateFields()
					.then(values => {
						form.resetFields();
						onCreate(values);
					})
					.catch(info => {
						console.log('Validate Failed:', info);
					});
			}}>
			<Form
				form={form}
				layout='vertical'
				hideRequiredMark
				scrollToFirstError
				colon={false}
				name='new-client-form'
				onKeyDown={e => (e.key === 'Enter' ? e.preventDefault() : '')}>
				<Form.Item
					name='name'
					label='Client name'
					required
					hasFeedback
					rules={[
						{
							required: true,
							message: 'This field is required!'
						},
						{
							validator: checkClientName
						}
					]}>
					<Input size='large' placeholder='Client name' className='form-input' />
				</Form.Item>
				<Form.Item
					name='description'
					label='Client Description'
					required
					hasFeedback
					rules={[
						{
							required: true,
							message: 'This field is required!'
						}
					]}>
					<Input size='large' placeholder='Client Description' className='form-input' />
				</Form.Item>
				<Form.Item
					name='contactDetails'
					label='Client Contact Details'
					required
					hasFeedback
					rules={[
						{
							required: true,
							message: 'This field is required!'
						}
					]}>
					<Input
						size='large'
						placeholder='Client Contact Details'
						className='form-input'
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default CreateClientModal;
