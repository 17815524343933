import React, { useState, useEffect, useContext } from 'react';
import { notification, DatePicker, Select, Form, Input } from 'antd';
import { customAlphabet } from 'nanoid';
import { isEmpty } from 'lodash';
import { useParams, useNavigate } from 'react-router-dom';
import callApi from '../utils/callApi';
import { AppContext } from '../context/GlobalState';
import {
	Layout,
	FileUploader,
	Loading,
	WebIconOutlined,
	TwitterIconOutlined,
	InstagramIconOutlined
} from '../components';

const { TextArea } = Input;
const { Option } = Select;

const CreateCampaign = () => {
	const navigate = useNavigate();
	const { clients, collections, fetchCampaigns } = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [collectionLogoUrl, setLogoUrl] = useState('');
	const [collectionBannerUrl, setBannerUrl] = useState('');
	const [campaignId, setCampaignId] = useState('');
	const [path, setPath] = useState('');
	const [agencyId, setAgencyId] = useState('');
	const pathName = window.location.pathname.split('/')?.[1];
	const params = useParams();
	const [createCampaignForm] = Form.useForm();

	useEffect(() => {
		const nanoid = customAlphabet('ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789', 10);
		const campaignId = pathName === 'manage' ? params?.campaignId : nanoid(6);
		setCampaignId(campaignId);
		const path = `campaigns/${campaignId}/`;
		setPath(path);
		const getUserAgencyId = JSON.parse(localStorage.getItem('user')).agencyId;
		setAgencyId(getUserAgencyId);
		setLoading(false);
	}, []); // eslint-disable-line

	const showError = message => {
		notification['error']({
			duration: 10,
			message: 'An error occurred',
			description: message
		});
	};

	const handleCreate = async values => {
		setLoading(true);
		const clientId = await getClientId(values?.clientName);
		const startDate = await toTimestamp(values?.startDate);
		const endDate = await toTimestamp(values?.endDate);

		const collectionPayload = {
			clientId: clientId,
			agencyId,
			campaignId,
			collectionName: values?.campaignName,
			collectionDescription: values?.collectionDescription,
			collectionLogoUrl,
			collectionBannerUrl,
			collectionType: values?.collectionType
		};
		const campaignPayload = {
			agencyId,
			campaignId,
			clientId: clientId,
			startDate: startDate,
			endDate: endDate,
			websiteUrl: values?.websiteUrl,
			twitterUrl: values?.twitterUrl,
			instagramUrl: values?.instagramUrl
		};

		try {
			const response = await callApi('put', 'campaign', campaignPayload);
			await callApi('put', 'collection', collectionPayload);
			if (!response?.error || !response?.status === 'error') {
				await fetchCampaigns();
				setLoading(false);
				navigate('/home');
			} else {
				setLoading(false);
				showError(response?.message || response?.error);
			}
		} catch (err) {
			setLoading(false);
			console.error('Error while creating campaign', err);
		}
	};

	const handleUpdate = async values => {
		setLoading(true);
		const clientId = await getClientId(values?.clientName);
		const startDate = await toTimestamp(values?.startDate);
		const endDate = await toTimestamp(values?.endDate);

		const collectionPayload = {
			clientId: clientId,
			agencyId,
			campaignId: params?.campaignId,
			collectionName: values?.campaignName,
			...(values?.collectionDescription && { collectionDescription: values?.collectionDescription }),
			...(collectionLogoUrl && { collectionLogoUrl: collectionLogoUrl }),
			...(collectionBannerUrl && { collectionBannerUrl: collectionBannerUrl }),
			...(values?.collectionType && { collectionType: values?.collectionType }),
			...(values?.websiteUrl && { websiteUrl: values?.websiteUrl }),
			...(values?.twitterUrl && { twitterUrl: values?.twitterUrl }),
			...(values?.instagramUrl && { instagramUrl: values?.instagramUrl }),
			operation: 'update'
		};
		const campaignPayload = {
			agencyId,
			campaignId: params?.campaignId,
			clientId: clientId,
			...(startDate && { startDate: startDate }),
			...(endDate && { endDate: endDate }),
			...(values?.websiteUrl && { websiteUrl: values?.websiteUrl }),
			...(values?.twitterUrl && { twitterUrl: values?.twitterUrl }),
			...(values?.instagramUrl && { instagramUrl: values?.instagramUrl }),
			operation: 'update'
		};

		try {
			const response = await callApi('post', 'campaign', campaignPayload);
			await callApi('post', 'collection', collectionPayload);
			if (!response?.error || !response?.status === 'error') {
				await fetchCampaigns();
				setLoading(false);
				navigate('/home');
			} else {
				setLoading(false);
				showError(response?.message || response?.error);
			}
		} catch (err) {
			setLoading(false);
			console.error('Error while updating campaign', err);
		}
	};

	const getClientId = async selectedClient =>
		clients?.find(item => item?.clientName === selectedClient)?.clientId;

	const toTimestamp = async dateStr => {
		const dt = Date.parse(dateStr);
		return dt.toString();
	};

	return (
		<Layout>
			{loading ? (
				<Loading />
			) : (
				<div className='create-campaign-page'>
					<div className='create-campaign-form'>
						<span className='header'>
							{pathName === 'manage' ? 'Update campaign' : 'Create a new campaign'}
						</span>
						<Form
							onFinish={pathName === 'manage' ? handleUpdate : handleCreate}
							form={createCampaignForm}
							size='large'
							layout='vertical'
							name='create-campaign'
							onKeyDown={e => (e.key === 'Enter' ? e.preventDefault() : '')}
							hideRequiredMark
							scrollToFirstError
							colon={false}>
							<Form.Item
								name='clientName'
								label='Select client'
								hasFeedback
								rules={[
									{
										required: true,
										message: 'This is a required field!'
									}
								]}>
								<Select placeholder='Select client'>
									{clients?.map(client => (
										<Option value={client?.clientName} key={client?.clientId}>
											{client?.clientName}
										</Option>
									))}
								</Select>
							</Form.Item>
							<div>
								{pathName === 'manage' ? (
									collections
										?.filter(collection => collection?.campaignId === params?.campaignId)
										?.map(collection => (
											<div key={collection?.campaignId}>
												<Form.Item
													name='campaignName'
													label='Campaign Name'
													required
													hasFeedback
													rules={[
														{
															required: true,
															message: 'This is a required field!'
														}
													]}>
													<Input />
												</Form.Item>
												<Form.Item
													name='campaignDescription'
													label='Campaign Description'
													hasFeedback
													rules={[
														{
															required: !(pathName === 'manage') ?? false,
															message: 'This is a required field!'
														}
													]}>
													<TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder='Campaign Description' />
												</Form.Item>
											</div>
										))
								) : (
									<div>
										<Form.Item
											name='campaignName'
											label='Campaign Name'
											hasFeedback
											rules={[
												{
													required: true,
													message: 'This is a required field!'
												}
											]}>
											<Input placeholder='Campaign Name' />
										</Form.Item>
										<Form.Item
											name='campaignDescription'
											label='Campaign Description'
											hasFeedback
											rules={[
												{
													required: true,
													message: 'This is a required field!'
												}
											]}>
											<TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder='Campaign Description' />
										</Form.Item>
									</div>
								)}
							</div>
							<Form.Item
								name='startDate'
								label='Starting Date'
								className='date-picker'
								hasFeedback
								rules={[
									{
										required: !(pathName === 'manage') ?? false,
										message: 'This is a required field!'
									}
								]}>
								<DatePicker />
							</Form.Item>
							<Form.Item
								name='endDate'
								label='Ending Date'
								className='date-picker'
								hasFeedback
								rules={[
									{
										required: !(pathName === 'manage') ?? false,
										message: 'This is a required field!'
									}
								]}>
								<DatePicker />
							</Form.Item>
							<Form.Item
								name='collectionType'
								label='Choose the collection type'
								hasFeedback
								rules={[
									{
										required: !(pathName === 'manage') ?? false,
										message: 'This is a required field!'
									}
								]}>
								<Select placeholder='Collection type'>
									<Option value='fungible' key='fungible'>
										fungible
									</Option>
									<Option value='collectible' key='collectible'>
										collectible
									</Option>
									<Option value='unique' key='unique'>
										unique
									</Option>
								</Select>
							</Form.Item>
							<Form.Item
								name='logoImg'
								label='Logo Image'
								extra='	This image will also be used for navigation. 350 x 350 recommended. PNG,
            JPEG and GIFs supported.'
								className='upload-logo-wrapper'>
								<FileUploader
									path={path}
									onReady={files => setLogoUrl(files?.[0]?.url)}
									imageType='Logo'
								/>
							</Form.Item>
							<Form.Item
								name='bannerImg'
								label='Banner image'
								extra='	This image will also be used for navigation. 350 x 350 recommended. PNG,
            JPEG and GIFs supported.'>
								<FileUploader
									path={path}
									onReady={files => setBannerUrl(files?.[0]?.url)}
								/>
							</Form.Item>
							<div className='social-wrapper'>
								<span className='title'>Further Links</span>
								<div className='links-container'>
									<Form.Item name='websiteUrl'>
										<Input placeholder='https://YourWebsite.com' prefix={<WebIconOutlined />} />
									</Form.Item>
									<Form.Item name='twitterUrl'>
										<Input placeholder='https://twitter.com/YourTwitterHandle' prefix={<TwitterIconOutlined />} />
									</Form.Item>
									<Form.Item name='instagramUrl'>
										<Input placeholder='https://www.instagram.com/YourInstagramHandle' prefix={<InstagramIconOutlined />} />
									</Form.Item>
								</div>
							</div>
							<div className='submit-buttons'>
								<button
									type='submit'
									className={
										pathName !== 'manage' &&
										(isEmpty(collectionLogoUrl) || isEmpty(collectionBannerUrl))
											? 'disabled-btn'
											: 'primary-btn'
									}>
									{pathName === 'manage' ? 'Update campaign' : 'Create campaign'}
								</button>
							</div>
						</Form>
					</div>
				</div>
			)}
		</Layout>
	);
};

export default CreateCampaign;
