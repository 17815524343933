import React from 'react';
import { Row, Space } from 'antd';

const StatisticsCard = props => (
	<div className='statistics-card-container'>
		<div className='statistics-card-image'>
			<img src={props?.item?.imageUrl} alt='campaign' />
		</div>
		<div className='stats-details-wrapper'>
			<Space direction='vertical'>
				<span className='header'>{props?.item?.itemName}</span>
				<Row>
					<div className='stats-details-box'>
						<span className='label'>{props?.mockData[0]?.label}</span>
						<span className='value'>{props?.mockData[0]?.claimed}</span>
					</div>
					<div className='stats-details-box'>
						<span className='label'>{props?.mockData[0]?.label}</span>
						<span className='value'>{props?.mockData[0]?.claimed}</span>
					</div>
				</Row>
			</Space>
			<Row>
				<div className='stats-details-box'>
					<span className='label'>{props?.mockData[0]?.label}</span>
					<span className='value'>{props?.mockData[0]?.claimed}</span>
				</div>
				<div className='stats-details-box'>
					<span className='label'>{props?.mockData[0]?.label}</span>
					<span className='value'>{props?.mockData[0]?.claimed}</span>
				</div>
			</Row>
		</div>
	</div>
);

export default StatisticsCard;
