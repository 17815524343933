import React from 'react';
import { Navbar, Footer } from '.';

const Layout = ({ children, login }) => (
	<div className='page-wrapper'>
		<div className='main-section'>
			{!login ? <Navbar /> : null}
			<div className='content'>{children}</div>
			{!login ? <Footer /> : null}
		</div>
	</div>
);

export default Layout;
